.pashudhan-vikas {
  font-family: Arial, sans-serif;
  color: #333;
  margin-left: 60px;
  width: 80%;
}

.hero {
  background: #f4f4f4;
  padding: 20px;
  text-align: center;
}

.mission,
.services,
.programs,
.gallery,
.contact {
  padding: 20px;
  margin: 10px 0;
}

h1,
h2 {
  color: #2c3e50;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

.contact p {
  margin: 5px 0;
}




@media (max-width: 768px) {
  .pashudhan-vikas {
    width: 90%;
    /* Adjust width for smaller screens */
    margin-left: 5%;
    /* Center on smaller screens */
    margin-right: 5%;
  }

  .hero,
  .mission,
  .services,
  .programs,
  .gallery,
  .contact {
    padding: 15px;
    /* Reduce padding on smaller screens */
  }

  h1 {
    font-size: 1.8em;
    /* Smaller heading size */
  }

  h2 {
    font-size: 1.5em;
    /* Smaller subheading size */
  }

  ul {
    padding-left: 15px;
    /* Adjust padding for lists */
  }
}

@media (max-width: 480px) {

  .hero,
  .mission,
  .services,
  .programs,
  .gallery,
  .contact {
    padding: 10px;
    /* Further reduce padding on extra-small screens */
  }

  h1 {
    font-size: 1.6em;
    /* Even smaller heading size for very small screens */
  }

  h2 {
    font-size: 1.3em;
  }

  ul {
    padding-left: 10px;
  }
}







/* page.css */

.vector-control {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0 auto;
  padding: 20px;
  width: 80%;
  max-width: 1200px;
}

/* Hero Section */
.hero {
  background-color: #e0f7fa;
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
}

.hero h1 {
  color: #00796b;
  font-size: 2em;
}

.hero p {
  font-size: 1.2em;
}

/* Section Styling */
.purpose,
.goals,
.services,
.awareness,
.contact {
  padding: 20px;
  margin: 10px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
}

h2 {
  color: #00695c;
  font-size: 1.6em;
  margin-bottom: 10px;
}

p,
ul {
  font-size: 1.1em;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  margin-bottom: 8px;
}

.contact p {
  margin: 5px 0;
}



/* Responsive Design */
@media (max-width: 768px) {
  .vector-control {
    width: 90%;
    padding: 15px;
  }

  .hero h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.4em;
  }

  p,
  ul {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 1.6em;
  }

  h2 {
    font-size: 1.2em;
  }

  .hero p,
  p,
  ul {
    font-size: 0.95em;
  }

  ul {
    padding-left: 15px;
  }
}

.tb-control {
  font-family: Arial, sans-serif;
  color: #333;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 1200px;
  /* Set a max width for large screens */
}

.hero {
  background-color: #f2f8ff;
  padding: 20px;
  text-align: center;
}

.purpose,
.goals,
.services,
.awareness,
.contact {
  padding: 20px;
  margin: 10px 0;
  background-color: #f9f9f9;
  border-radius: 5px;
}

h1,
h2 {
  color: #1e88e5;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

.contact p {
  margin: 5px 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tb-control {
    width: 90%;
    /* Adjust width for smaller screens */
    margin-left: 5%;
    /* Center on smaller screens */
    margin-right: 5%;
  }

  .hero,
  .purpose,
  .goals,
  .services,
  .awareness,
  .contact {
    padding: 15px;
    /* Reduce padding on smaller screens */
  }

  h1 {
    font-size: 1.8em;
    /* Smaller heading size */
  }

  h2 {
    font-size: 1.5em;
    /* Smaller subheading size */
  }

  ul {
    padding-left: 15px;
    /* Adjust padding for lists */
  }
}

@media (max-width: 480px) {

  .hero,
  .purpose,
  .goals,
  .services,
  .awareness,
  .contact {
    padding: 10px;
    /* Further reduce padding on extra-small screens */
  }

  h1 {
    font-size: 1.6em;
    /* Even smaller heading size for very small screens */
  }

  h2 {
    font-size: 1.3em;
  }

  ul {
    padding-left: 10px;
  }
}








/* page.css */

.animal-disease-control {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0 auto;
  padding: 20px;
  width: 80%;
  max-width: 1200px;
}

/* Hero Section */
.hero {
  background-color: #e0f7fa;
  padding: 30px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
}

.hero h1 {
  color: #00796b;
  font-size: 2em;
}

.hero p {
  font-size: 1.2em;
}

/* Section Styling */
.purpose,
.goals,
.services,
.awareness,
.contact {
  padding: 20px;
  margin: 10px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
}

h2 {
  color: #00695c;
  font-size: 1.6em;
  margin-bottom: 10px;
}

p,
ul {
  font-size: 1.1em;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  margin-bottom: 8px;
}

.contact p {
  margin: 5px 0;
}

/* Footer */
footer {
  background-color: #004d40;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 0.9em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .animal-disease-control {
    width: 90%;
    padding: 15px;
  }

  .hero h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.4em;
  }

  p,
  ul {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 1.6em;
  }

  h2 {
    font-size: 1.2em;
  }

  .hero p,
  p,
  ul {
    font-size: 0.95em;
  }

  ul {
    padding-left: 15px;
  }
}











.blogs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.blog2 {
  width: 23%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.blog2 img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog2:hover img {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 24px;
  margin-top: 100px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .blogs {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .blog2 {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .blog2 {
    width: 95%;
  }
}





.whatsapp-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.whatsapp-btn:hover {
  transform: scale(1.1);
}

.whatsapp-btn svg {
  color: white;
}

@media (max-width: 768px) {
  .whatsapp-btn {
    right: 10px;
    bottom: 20px;
  }
}




.naari {
  width: 80%;
  height: auto;
  border: 1px solid black;
  margin-left: 10%;
  margin-top: 20px;
  padding-bottom: 50px;
}

.naari h1 {
  font-size: 35px;
  text-align: center;
  margin-top: 20px;
}

.naari h2 {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

.naari p {
  margin-left: 20px;
  margin-top: 15px;

}

.naari h3 {
  text-align: center;
  color: #333;
  font-weight: bold;
}

.naari img {
  margin-left: 50px;
}


/* General pagination container styles */
.pagination-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap; /* Ensures pagination wraps on smaller screens */
  padding: 0;
}

/* Pagination style adjustments */
.pagination-container .pagination {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Adds space between pagination items */
}

/* Adjusting individual pagination item padding */
.pagination-container .pagination-item {
  padding: 8px 15px; /* Padding for larger screens */
  font-size: 14px; /* Default font size */
}

/* For smaller screens (mobile devices) */
@media (max-width: 767px) {
  .pagination-container .pagination-item {
    padding: 5px 8px; /* Reduced padding for mobile */
    font-size: 12px; /* Smaller font size */
  }
  .naari img {
    margin-left: 0px;
    width: 100%;
  }
  
  /* Center pagination container on small screens */
  .pagination-container {
    justify-content: center;
  }
}


