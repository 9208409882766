.head-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: linear-gradient(135deg, #ff7eb3, #ff758c);
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .main-heading {
    font-size: 1.7rem;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-bottom: 10px;
  }
  
  .sub-heading {
    font-size: 1.8rem;
    font-weight: 500;
    color: #ffe8f1;
    text-align: center;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .main-heading {
      font-size: 1.2rem;
    }
  
    .sub-heading {
      font-size: 1rem;
    }
  }
  