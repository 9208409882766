.sections {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
    flex-direction: row;
}

.section2 {
    width: 45%;
    height: 100%;
    margin-left: 3%;
    opacity: 0;
    transform: translateX(100%);
    animation: slideIn 1s ease-in-out forwards;
    animation-delay: 0.5s;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.section2 h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 25px;
}

.section2 h2 {
    font-size: 1.2rem;
    font-weight: bold;
}

.section2 h3{
font-size: 1rem;
font-weight: bold;
}

.section2 p {
    text-align: justify;
    width: 90%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contact-button {
    background-color: #ff9933;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
    margin-top: 25px;
    margin-left: 50px;
}

.contact-button:hover {
    background-color: #e6892c;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.contact-button:focus {
    outline: none;
    border: 2px solid #ff6600;
}

.contact-button span {
    transition: all 0.3s ease;
}

.section2 img {
    width: 100%;
    height: auto;
    margin-top: 25px;
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.section2 img:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 768px) {
    .sections {
        flex-direction: column;
        height: auto;
    }

    .section2 {
        width: 90%;
        margin-left: 0;
        margin-bottom: 20px;
        animation-delay: 0.3s;
    }

    .section2 h1 {
        font-size: 1.5rem;
    }

    .section2 p {
        font-size: 14px;
    }

    .contact-button {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
    }

    .section2 img {
        width: 100%;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 480px) {
    .section2 {
        width: 95%;
        margin-left: 10px;
        margin-bottom: 20px;
        animation-delay: 0.3s;
    }

    .section2 h1 {
        font-size: 1.2rem;
        margin-left: 10px;
    }

    .section2 p {
        font-size: 13px;
        margin-left: 10px;
    }

    .contact-button {
        font-size: 14px;
        padding: 8px 15px;
        margin-left: 0px;
    }

    .section2 img {
        margin-top: 10px;
        margin-left: 0px;
    }
}











.video-container {
    display: flex;
    gap: 10px; /* Space between videos */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center;
    padding: 20px;
  }
  
  .video-item {
    width: 300px; /* Adjust width as needed */
    height: 300px;
    border-radius: 10px; /* Optional: rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  